@import './fontello-f6c36b3f/css/share-icons.css';
@import "./sass_includes/variables";
@import "./sass_includes/desktop";
@import "./sass_includes/animations";

/** mobile share start **/
$border-radius: 50%;
$offset-position: -10rem;
$show-position-item: -6rem;
$item-size: 3.5rem;
$btn-open-right: 1rem;

@mixin share-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $item-size;
  height: $item-size;
  border-radius: $border-radius;
  font-size: 1.5rem;
  border: none;
  text-decoration: none;
  transition: transform 0.25s ease-in-out;
  right: $show-position-item;
  padding: 0;
  margin: 0 0 0.5rem 0;
  position: relative;
}

.sharer {

  &-mobile {
    height: 100%;
    pointer-events: none;
    position: fixed;
    z-index: $zindex-layer + 1;
    top: 1rem;
    right: 0;
    padding-top: 0;
    overflow-y: hidden;
    background-color: transparent;
    width: $item-size + 1rem;

    &-wrapper {
      display: block;
      transform: translateX(0);
      position: fixed;
      z-index: $zindex-layer;
      top: 0;
      right: $offset-position;
      padding-top: 0;
      background-color: transparent;
      width: $item-size;

      &.sharer-mobile-show {
        transform: translateX($offset-position);
        animation: fadein ease-in-out 0.3s forwards;
      }

      &.sharer-mobile-close {
        animation: fadeout ease-in-out 0.3s forwards;
      }
    }

    &.sharer-mobile-active {
      pointer-events: initial;

      .sharer-mobile-item {
        transform: translateX($show-position-item);
      }
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-item {
      @include share-item();

      a:link {
        text-decoration: none;
      }
    }

    // item variants here
    @for $i from 1 through $mobile-item-count {
      &-item-var#{$i} {
        $color: lighten($base-color, $i * $mobile-item-lighten);
        background-color: $color;
        transition-delay: $i * 0.03s;

        &:focus, &:hover {
          outline: none;
          text-decoration: none;
          background-color: lighten($color, 10%);
        }
      }
    }

    &-btn-open {
      @include share-item();

      position: fixed;
      top: 1rem;
      right: $btn-open-right;
      color: white;
      background-color: $base-color;

      &:focus, &:hover {
        outline: none;
        background-color: lighten($base-color, 5%);
      }
    }

    &-btn-close {
      background-color: $base-color;
      color: white;

      &:focus, &:hover {
        outline: none;
        background-color: lighten($base-color, 5%);
      }

    }
  }

  .close-nav {
    background-color: $base-color;
    color: white;
    border: none;
  }
}

/** mobile share end **/

@media screen and (min-width: $desktop-breakpoint) {
  .sharer .sharer-mobile {
    display: none;
  }
}

/* @media screen and (min-width: 768px) {

  #sharer .mobile-share {
    width: 5rem;
  }

  #sharer .mobshare-item {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
  }

  #sharer .sharer-open {
    width: 5rem;
    height: 5rem;
    font-size: 2.5rem;
  }
} */

@import "./sass_includes/media_queries";