@keyframes fadein {
    from {
      opacity: 1%;
    }
  
    to {
      opacity: 100%;
    }
  }
  
  @keyframes fadeout {
    from {
      opacity: 100%;
    }
  
    to {
      opacity: 1%;
    }
  }
  