// generic media queries

// don't show on desktop
@media screen and (min-width: $desktop-breakpoint) {
    .sharer .sharer-mobile {
        display: none;
    }

    .sharer-mobile-wrapper {
        display: none;
    }
}

// don't show for print
@media print {
    #sharer {
        display: none;
    }
}
