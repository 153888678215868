
// common variables here

// brand setup

$ltegroup: (
    "desktop-base-color": lighten(hsl(32, 75%, 29%), 15%),
    "mobile-base-color": hsl(32, 75%, 29%),
    "mobile-lighten-step": 5%,
    "mobile-position-top": 0,
);

$tmc: (
    "desktop-base-color": lighten(#183145, 20%),
    "mobile-base-color": #183145,
    "mobile-lighten-step": 5%,
    "mobile-position-top": 0,
);

$ucen: (
    "desktop-base-color": lighten(#183145, 20%),
    "mobile-base-color": #183145,
    "mobile-lighten-step": 5%,
    "mobile-position-top": 0,
);

$mol: (
    "desktop-base-color": hsl(183, 100%, 27%),
    "mobile-base-color": hsl(183, 100%, 20%),
    "mobile-lighten-step": 2%,
    "mobile-position-top": 0,
);

$novus: (
    "desktop-base-color": hsl(43, 96%, 34%),
    "mobile-base-color": hsl(43, 96%, 24%),
    "mobile-lighten-step": 2%,
    "mobile-position-top": 0,
);

$current-brand: $tmc;

$base-color: map-get($current-brand, "mobile-base-color");
$desktop-breakpoint: 1280px;
$zindex-layer: 900;

// desktop specific
$desktop-item-size: 2.2rem;
$desktop-font-size: 1.2rem;
$desktop-base-color: map-get($current-brand, "desktop-base-color");

// mobile specific
$mobile-top: map-get($current-brand, "mobile-position-top");
$mobile-item-lighten: map-get($current-brand, "mobile-lighten-step");
$mobile-item-count: 6;
