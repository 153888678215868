@font-face {
  font-family: 'share-icons';
  src: url('../font/share-icons.eot?22234620');
  src: url('../font/share-icons.eot?22234620#iefix') format('embedded-opentype'),
       url('../font/share-icons.woff2?22234620') format('woff2'),
       url('../font/share-icons.woff?22234620') format('woff'),
       url('../font/share-icons.ttf?22234620') format('truetype'),
       url('../font/share-icons.svg?22234620#share-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'share-icons';
    src: url('../font/share-icons.svg?22234620#share-icons') format('svg');
  }
}
*/
[class^="sa-icon-"]:before, [class*=" sa-icon-"]:before {
  font-family: "share-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.sa-icon-cancel:before { content: '\e800'; } /* '' */
.sa-icon-twitter-x:before { content: '\e80a'; } /* '' */
.sa-icon-facebook-messenger:before { content: '\e819'; } /* '' */
.sa-icon-twitter:before { content: '\f099'; } /* '' */
.sa-icon-facebook:before { content: '\f09a'; } /* '' */
.sa-icon-mail-alt:before { content: '\f0e0'; } /* '' */
.sa-icon-linkedin:before { content: '\f0e1'; } /* '' */
.sa-icon-share:before { content: '\f1e0'; } /* '' */
.sa-icon-whatsapp:before { content: '\f232'; } /* '' */
