@font-face {
  font-family: share-icons;
  src: url("share-icons.21614844.eot");
  src: url("share-icons.21614844.eot#iefix") format("embedded-opentype"), url("share-icons.56518761.woff2") format("woff2"), url("share-icons.f632a853.woff") format("woff"), url("share-icons.f095916c.ttf") format("truetype"), url("share-icons.f753f61c.svg#share-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="sa-icon-"]:before, [class*=" sa-icon-"]:before {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  margin-left: .2em;
  margin-right: .2em;
  font-family: share-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.sa-icon-cancel:before {
  content: "";
}

.sa-icon-twitter-x:before {
  content: "";
}

.sa-icon-facebook-messenger:before {
  content: "";
}

.sa-icon-twitter:before {
  content: "";
}

.sa-icon-facebook:before {
  content: "";
}

.sa-icon-mail-alt:before {
  content: "";
}

.sa-icon-linkedin:before {
  content: "";
}

.sa-icon-share:before {
  content: "";
}

.sa-icon-whatsapp:before {
  content: "";
}

.sharer .sharer-tab {
  display: none;
}

@media screen and (min-width: 1280px) {
  .sharer .sharer-tab {
    z-index: 9999;
    flex-direction: column;
    display: flex;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.sharer .sa-icon {
  color: #fff;
}

.sharer-tab-item {
  filter: grayscale();
  background-color: #326791;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 1.2rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: flex;
}

.sharer-tab-item:hover, .sharer-tab-item:focus {
  filter: grayscale(0%);
  outline: none;
  transition: all .3s ease-in-out;
  transform: translateX(10px);
}

@keyframes fadein {
  from {
    opacity: .01;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: .01;
  }
}

.sharer-mobile {
  pointer-events: none;
  z-index: 901;
  background-color: #0000;
  width: 4.5rem;
  height: 100%;
  padding-top: 0;
  position: fixed;
  top: 1rem;
  right: 0;
  overflow-y: hidden;
}

.sharer-mobile-wrapper {
  z-index: 900;
  background-color: #0000;
  width: 3.5rem;
  padding-top: 0;
  display: block;
  position: fixed;
  top: 0;
  right: -10rem;
  transform: translateX(0);
}

.sharer-mobile-wrapper.sharer-mobile-show {
  animation: .3s ease-in-out forwards fadein;
  transform: translateX(-10rem);
}

.sharer-mobile-wrapper.sharer-mobile-close {
  animation: .3s ease-in-out forwards fadeout;
}

.sharer-mobile.sharer-mobile-active {
  pointer-events: initial;
}

.sharer-mobile.sharer-mobile-active .sharer-mobile-item {
  transform: translateX(-6rem);
}

.sharer-mobile-list {
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.sharer-mobile-item {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 0 .5rem;
  padding: 0;
  font-size: 1.5rem;
  text-decoration: none;
  transition: transform .25s ease-in-out;
  display: flex;
  position: relative;
  right: -6rem;
}

.sharer-mobile-item a:link {
  text-decoration: none;
}

.sharer-mobile-item-var1 {
  background-color: #1f3e58;
  transition-delay: 30ms;
}

.sharer-mobile-item-var1:focus, .sharer-mobile-item-var1:hover {
  background-color: #2c597e;
  outline: none;
  text-decoration: none;
}

.sharer-mobile-item-var2 {
  background-color: #254c6b;
  transition-delay: 60ms;
}

.sharer-mobile-item-var2:focus, .sharer-mobile-item-var2:hover {
  background-color: #326791;
  outline: none;
  text-decoration: none;
}

.sharer-mobile-item-var3 {
  background-color: #2c597e;
  transition-delay: 90ms;
}

.sharer-mobile-item-var3:focus, .sharer-mobile-item-var3:hover {
  background-color: #3974a4;
  outline: none;
  text-decoration: none;
}

.sharer-mobile-item-var4 {
  background-color: #326791;
  transition-delay: .12s;
}

.sharer-mobile-item-var4:focus, .sharer-mobile-item-var4:hover {
  background-color: #3f82b7;
  outline: none;
  text-decoration: none;
}

.sharer-mobile-item-var5 {
  background-color: #3974a4;
  transition-delay: .15s;
}

.sharer-mobile-item-var5:focus, .sharer-mobile-item-var5:hover {
  background-color: #4e8ec1;
  outline: none;
  text-decoration: none;
}

.sharer-mobile-item-var6 {
  background-color: #3f82b7;
  transition-delay: .18s;
}

.sharer-mobile-item-var6:focus, .sharer-mobile-item-var6:hover {
  background-color: #619ac8;
  outline: none;
  text-decoration: none;
}

.sharer-mobile-btn-open {
  cursor: pointer;
  color: #fff;
  background-color: #183145;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 0 .5rem;
  padding: 0;
  font-size: 1.5rem;
  text-decoration: none;
  transition: transform .25s ease-in-out;
  display: flex;
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.sharer-mobile-btn-open:focus, .sharer-mobile-btn-open:hover {
  background-color: #1f3e58;
  outline: none;
}

.sharer-mobile-btn-close {
  color: #fff;
  background-color: #183145;
}

.sharer-mobile-btn-close:focus, .sharer-mobile-btn-close:hover {
  background-color: #1f3e58;
  outline: none;
}

.sharer .close-nav {
  color: #fff;
  background-color: #183145;
  border: none;
}

@media screen and (min-width: 1280px) {
  .sharer .sharer-mobile, .sharer-mobile-wrapper {
    display: none;
  }
}

@media print {
  #sharer {
    display: none;
  }
}

/*# sourceMappingURL=share-alt.css.map */
