// This file holds the common desktop theming

/** sideshare start **/

.sharer .sharer-tab {
  display: none;
}

@media screen and (min-width: $desktop-breakpoint) {
  .sharer .sharer-tab {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
  }
}

.sharer {

  .sa-icon {
    color: white;
  }

  &-tab {

    &-item {
      filter: grayscale(100%);
      transition: 0.3s ease-in-out;
      background-color: $desktop-base-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $desktop-item-size;
      height: $desktop-item-size;
      font-size: $desktop-font-size;
      text-decoration: none;

      &:hover, &:focus {
        outline: none;
        filter: grayscale(0%);
        transform: translatex(10px);
        transition: 0.3s ease-in-out
      }

      // &.sharer {
      //   $items: 5;
      //   $amount: 5%;

      //   @for $i from 1 through $items {
      //     &-tab-item-var#{$i} {
      //       background-color: lighten($base-color, $i * $amount);
      //     }
      //   }
      // }
    }
  }
}

/** sideshare end **/
